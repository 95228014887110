// Here you can add other styles
.anouar {
  height: 29.7cm;
  width: 21cm;
}
.backCard {
  height: 40cm;
  //border: solid 1px black;
}

.page-break {
  page-break-after: always;
  page-break-inside: avoid;
  clear: both;
}
.page-break-before {
  page-break-before: always;
  page-break-inside: avoid;
  clear: both;
}
.link {
  text-decoration: none !important;
  color: black !important;
}
.link :hover {
  text-decoration: none !important;
  color: black !important;
}
.line {
  color: gray;
}


/*--------Pagination-------------*/

/* Custom CSS for pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination .page-item {
  margin: 0 5px;
  
}

.pagination .page-link {
  padding: 5px 10px; /* Adjust size here */
  font-size: 14px; /* Adjust font size here */
}

.pagination .active .page-link {
  background-color: #007bff; /* Active button color */
  border-color: #007bff;
  
  color: white; /* Active button text color */
}

.pagination .page-link:hover {
  background-color: #0056b3; /* Hover color */
  border-color: #0056b3;
 
  color: white;
}




/*---------------------------------*/
.facebook-btn {
  background-color: #405d9b;
  color: white;
  font-size: 16px;
  height: 42px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
}
.facebook-btn:hover {
  color: white;
  background-color: #2c509c;
}
//feedBack form erro alerts
.feedBack {
  margin-left: 40px;
}

.google-btn {
  height: 42px;
  font-size: 16px;
}

.register-btn {
  font-size: 16px !important;
  color: white;
  height: 40px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2), 0 3px 3px 0 rgba(0, 0, 0, 0.19);
}
.register-btn:hover {
  color: white;
  /* background-color: rgba(89, 134, 216, 0.884);*/
}

.card-btn {
  color: white;
}
.card-btn:hover {
  color: white;
}

.lock_Background {
  width: 100wh;
  height: 90vh;

  background: linear-gradient(
    -45deg,
    rgb(65, 96, 233),
    rgba(241, 99, 194, 0.966),
    rgb(7, 141, 190),
    #23d5ab
  );
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
ul.geoList {
  list-style-type: square;
}

.action-btnUser {
  background-image: url(http://localhost:3000/src/assets/img/EditUser.png);
}

.mapModal {
  width: 500px;
  height: 500px;
}

.context-menu {
  z-index: 1;
}
.pagination-z-index {
  z-index: 0;
}
.context-menuItem {
  padding-right: 40px;
  padding-left: 10px;
  margin: 0px;
  display: block;
}
.context-menuItem:hover {
  background-color: lightgrey;
}

.update-context-menu-item {
  margin: 8px 12px 8px 8px;
}

.deleteModal-width {
  width: 350px;
  font-size: 16px;
}
//theme gallery
.gallery {
  transition: transform .2s;
  margin: 20px;
  width:300px;
  height:200px;
  
}
.gallery:hover{
  transform: scale(1.01);
  border: 1px solid rgb(39, 75, 235);
}


 .removeBadge{
  position: relative;
  width: 20px;
  height:20px;
  border-radius: 50% ;
  background-color:rgb(124, 99, 236);
  bottom: 235px;
  left: 315px;
  padding-left: 6px;
  padding-top: 1px;
  cursor:pointer;
}
//end theme gallery

.carouselItem {
  height: 300px;
  overflow: hidden;
  background-color: rgb(245, 252, 209);
  text-align: left;
}

.paragraph {
  font-size: "12px";
  font-family: Arial;
  color: black;
}
.head {
  font-size: "16px";
  font-family: Arial;
  color: rgb(74, 144, 226);
  font-weight: bold;
  margin-bottom: 10px;
}
.head-summary {
  background-image: linear-gradient(to right, lightblue, white);
  width: 250px;
  padding: 10px;
  margin-left: 20px;
  border-radius: 5px 0 0 5px;
}
.responsiveImg {
  max-width: 100%;
  height: auto;
}


/* SCrollbar Drawer */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(163, 163, 163); 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8d8d8d; 
}


// line with centred text
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}

// responsive layout 
.form-container{
  width: 70%;
}
// @media (max-width: 575.98px) { 
  
//  }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
  .form-container{
  width: 100%;
} 
}


// lock screen
.react-lock-screen__ui {
  width: 300px;
  position: fixed;
  border: 4px solid #333;
  background: #fff;
  text-align: center;
  padding: 16px;
  border-radius: 4px;
  top: calc(30% - 152px / 2);
  left: calc(49% - 300px / 2);
}
